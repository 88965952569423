import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  AnnouncementWrapper,
  Heading,
} from '../../styles/Components/Announcement/AnnouncementStyles';
import Icon from '../../styles/atoms/icons';

const Announcement = () => {
  const data = useStaticQuery(getData);
  const announcement = data?.data?.edges[0]?.node?.announcement;
  const [changeNav, setChangeNav] = useState(false);

  const scrollHandler = () => {
    if (window.scrollY > 1) {
      setChangeNav(true);
    } else {
      setChangeNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    scrollHandler();
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    announcement && (
      <AnnouncementWrapper changeNav={changeNav}>
        <Heading className="ml-md-5 mt-2 mt-md-0 mr-md-3 pr-md-1 pl-md-4">
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: announcement }}
          />

          <div className="action-links">
            <a href="tel:+1-408-675-1879" className="phone-wrapper">
              <span className="phone-icon">
                <Icon id="BiPhone" size={12} />
              </span>
              <span className="call-label">Call Us</span>
              <span className="call-number"> (408)-675-1879</span>
            </a>
            <div className="separator d-none d-md-inline-block" />
            <a
              href="https://app.shopmonkey.cloud/login"
              className="my-auto mr-md-5 sign-in d-none d-md-inline-block"
            >
              Sign In
            </a>
          </div>
        </Heading>
      </AnnouncementWrapper>
    )
  );
};

export const getData = graphql`
  {
    data: allDatoCmsHeaderComponent(
      filter: { internalName: { eq: "Global Header" } }
    ) {
      edges {
        node {
          announcement
        }
      }
    }
  }
`;

export default Announcement;
